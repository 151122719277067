@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/@angular/material/theming';
@import "~@ng-select/ng-select/themes/material.theme.css";

@include mat-core();

$my-primary: (
    50: #a76ca5,
    100: #8d538b,
    200: #7e3a7c,
    300: #622d60,
    400: #6a2e6a,
    500: #5e285e,
    600: #4f0651,
    700: #320034,
    800: #720076,
    900: #320034,
    A100: #2e002f,
    A200: #2c022d,
    A400: #2e012f,
    A700: #180019,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$custom-theme-primary: mat-palette($my-primary);
$custom-theme-accent: mat-palette($mat-amber);
$custom-theme-warn: mat-palette($mat-red);

$custom-theme: mat-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);

@include angular-material-theme($custom-theme);

$dark-primary: mat-palette($my-primary);
$dark-accent: mat-palette($mat-amber);
$dark-warn: mat-palette($mat-red);

$dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

.dark-theme {
    @include angular-material-theme($dark-theme);
}

$light-primary: mat-palette($mat-grey, 200, 500, 300);
$light-accent: mat-palette($mat-brown, 200);
$light-warn: mat-palette($mat-deep-orange, 200);

$light-theme: mat-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);

.light-theme {
    @include angular-material-theme($light-theme)
}

.backgroud-primary {
    background-color: mat-color($custom-theme-primary, default) !important;
}

.backgroud-primary-lighter {
    background-color: mat-color($custom-theme-primary, lighter) !important;
}

.backgroud-primary-darker {
    background-color: mat-color($custom-theme-primary, darker) !important;
}

.color-primary {
    color: mat-color($custom-theme-primary, default) !important;
}

.color-primary-lighter {
    color: mat-color($custom-theme-primary, lighter) !important;
}

.color-primary-darker {
    color: mat-color($custom-theme-primary, darker) !important;
}

.border-left-primary {
    border-left-color: mat-color($custom-theme-primary, default) !important;
}

.backgroud-accent {
    background-color: mat-color($custom-theme-accent, default) !important;
}

.backgroud-accent-lighter {
    background-color: mat-color($custom-theme-accent, lighter) !important;
}

.backgroud-accent-darker {
    background-color: mat-color($custom-theme-accent, darker) !important;
}

.color-accent {
    color: mat-color($custom-theme-accent, default) !important;
}

.color-accent-lighter {
    color: mat-color($custom-theme-accent, lighter) !important;
}

.color-accent-darker {
    color: mat-color($custom-theme-accent, darker) !important;
}

.mat-tab-group-accent {
    .mat-ink-bar {
        background-color: mat-color($custom-theme-accent, darker) !important;
    }

    .mat-tab-label-active {
        background-color: mat-color($custom-theme-accent, darker, 0.2) !important;
    }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: rgb(255, 111, 0);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: rgba(255, 111, 0, 0.50);
}

.backgroud-warn {
    background-color: mat-color($custom-theme-warn, default) !important;
}

.backgroud-warn-lighter {
    background-color: mat-color($custom-theme-warn, lighter) !important;
}

.backgroud-warn-darker {
    background-color: mat-color($custom-theme-warn, darker) !important;
}

.color-warn {
    color: mat-color($custom-theme-warn, default) !important;
}

.color-warn-lighter {
    color: mat-color($custom-theme-warn, lighter) !important;
}

.color-warn-darker {
    color: mat-color($custom-theme-warn, darker) !important;
}

.color-danger {
    color: #ff7171;
}

.color-sucess {
    color: #009f45;
}

.color-info {
    color: blue;
}

.color-waring {
    color: #fff6a3;
}

.color-bloqueado {
    color: #686866;
}

.bkg-color-danger {
    background-color: #ff7171 !important;
}

.bkg-color-sucess {
    background-color: #75ffb1 !important;
}

.bkg-color-waring {
    background-color: #fff6a3 !important;
}

.bkg-color-info {
    background-color: #a3b2ff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    color: mat-color($custom-theme-primary, lighter) !important;
}

.ng-select.ng-select-focused .ng-select-container:after {
    border-color: mat-color($custom-theme-primary, darker) !important
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: mat-color($custom-theme-primary, darker) !important
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    color: mat-color($custom-theme-warn, darker) !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border: 0px none #ffffff;
}

::-webkit-scrollbar-button {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:active {
    background: #929292;
}

::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 100px;
}

::-webkit-scrollbar-track:hover {
    background: transparent;
}

::-webkit-scrollbar-track:active {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
