
@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
html {
    background-color: white;
    height: 100%;
}

body {
    background-color: transparent;
}

@media (max-width: 1023px) {
    .mat-table{
        display: block;
        overflow-y: auto;
    }
}

.mat-slide-toggle-thumb-container {
    z-index: 0 !important;
}

.cursor-pointer{
    cursor: pointer !important;
}

* {
    font-family: 'Poppins', sans-serif;
}

.button-center-input {
    @media (min-width: 1024px) {
        margin-top: 13px !important;
    }
}

.mat-select-panel {
  max-width: 100vw !important;
}
.ng-select.ng-select-multiple {
    .ng-select-container {
        .ng-value-container {
            .ng-value {
                border-radius: 16px;
                height: 32px;
                position: relative;
                display: flex;
                align-items: center;
                background-color: transparent;
                color: rgba(0, 0, 0, 0.87);
                border: 1px solid rgba(0, 0, 0, 0.16);
                padding: 2px 12px;

                .ng-value-icon {
                    position: absolute;
                    right: 12px;
                    color: rgba(0, 0, 0, 0.87);
                    top: 0;
                    bottom: 0;
                    padding: 0;
                    width: 17px;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .ng-value-label {
                    font-weight: 400;
                    width: auto;
                    margin-right: 20px;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.mat-bottom-sheet-container-large {
    min-width: 90vw !important;
    min-height: 90vh !important;
}

.mat-bottom-sheet-container-medium {
    min-width: 90vw !important;
    min-height: 80vh !important;

    .content-sheet {
        padding: 16px 0 !important;
    }
}

.mat-horizontal-stepper-header {
    display: block !important;

    .mat-step-icon {
        margin: 8px auto 8px auto !important;
    }
}

.no-focus {
    outline: none;
}

.mat-dialog-container {
    position: relative;
}

.inputfile{
    display: none;
}

.custom-modalbox{
    @media (max-width: 1023px) {
        width: 90% !important;
        max-width: 90% !important;
    }
}

.list{

    @media (max-width: 1023px) {
        padding: 10px 24px 10px 24px;
    }

    @media (min-width: 1024px) {
        padding: 28px 24px 0 24px;
    }

    .selection-action {
        background-color: #ffffff;
        h1{
            font-size: 18px;
            font-weight: 600;
        }

        @media (min-width: 1024px) {
            h1 {
                margin: 19px 0 0 0;
            }
        }
    }
    .selection-action-spinner {
        padding-bottom: 28px;
    }
    table {
        width: 100%
    }
    
    .dsp-inline{
        display: inline-block;
    }
    .align-title{
        text-align: center;
        width: calc(100% - 100px);
        padding-left: 100px;
    }
    
    .mat-dialog-actions {
        padding: 16px 0;
    }
    h1 {
        text-align: center;
    }
    .grid-fields {
        margin-bottom: 0 !important;
    }
    mat-slide-toggle {
        margin-top: 16px;
    }
    mat-progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .max-width-tel {
        max-width: calc(25% - 14px) !important;
    }
    .max-width-mail {
        max-width: calc(50% - 28px) !important;
    }
    .button-add-tel {
        margin-top: 4px;
    }
    
    .button-novo {
        position: fixed !important;
        right: 16px;
        bottom: 16px;
        width: 128px;
        border-radius: 30px !important;
    }

}

.list:not(.dialog){
    height: calc(100vh - 52px);
}

.boxform{
    border: 1px solid #cfcfcf;
    min-height: calc(100vh - 190px);
    max-height: calc(100vh - 190px);
    border-radius: 6px;
    overflow: auto;
}

a mat-icon{
    cursor: pointer;
}

.boxform.form{
    display: block;
    overflow: auto;
    padding: 12px;
}

.row-selected{
    background-color: #dfdfdf;
}

.list{

    form{
        margin-bottom: 10px;
    }
}

.mat-card-actions{
    margin: 0px !important;
    padding: 0px !important;

    .mat-button{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

.item-list{
    border-bottom: 1px solid #cbcbcb;
    padding: 10px;
    cursor: pointer;
}

.item-box{
    font-size: 13px;
    padding: 0px 7px;
    color: white;
    border-radius: 6px;
}

.mb-20{
    margin-bottom: 20px !important;
}

.boxform{
    .selected{
        background: #d5d5d5;
    }
}

/* SUBSTITUIR BOOTSTRAP */

mat-form-field {
    width: 100%;
}

mat-hint {
    color: rgba(0,0,0,.6) !important;
}

.mdc-list-item__primary-text {
    font-size: 14px !important;
}

.mat-mdc-unelevated-button>.mat-icon{
    font-size: x-large !important;
    height: 24px !important;
    width: 24px !important;
}

.mdc-notched-outline__notch{
    border-right: none !important;
}

.mdc-dialog__title{
    padding: 0px 0px 20px 0px !important; 
}

hr{
    margin-bottom: 10px !important;
}

.mat-mdc-dialog-container, .mat-mdc-dialog-container .mdc-dialog__container, .mat-mdc-dialog-container .mdc-dialog__surface {
    max-height: initial !important;
    min-height: initial !important;
    min-width: initial !important;
    max-width: initial !important;
}

.mat-mdc-dialog-actions .mat-button-base+.mat-button-base, .mat-mdc-dialog-actions .mat-mdc-button-base+.mat-mdc-button-base {
    margin-left: 0px !important;
}

.mdc-card__actions{
    min-height: 0px !important;
    padding: 0px !important;
}
.mat-mdc-autocomplete-panel {
    max-height: 450px!important;
}